<template>
  <ModalBase :hasFooter="false" :id="id" size="modal-md">
    <div slot="content">
      <div
        v-if="
          !error &&
          (data ||
            transaction.type === 'otherTransfer' ||
            transaction.type === 'bankTransferFee' ||
            transaction.type === 'returnBankTransferFee' ||
            transaction.type === 'billing' ||
            transaction.type === 'internalTransfer') &&
          transaction
        "
        class="details"
      >
        <div class="d-flex flex-column align-items-center" style="width: 80%">
          <div class="circle">
            <AppIconHistoric :type="transaction.type" :view="'icon'" />
          </div>
          <div
            v-if="transaction.type === 'billing' && transaction.card !== null"
            class="value"
          >
            <span>
              {{
                dataInstallment.amount
                  | currency('R$ ', 2, {
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                  })
              }}
            </span>
          </div>
          <div
            v-else-if="
              transaction.type === 'billing' && transaction.bankSlip !== null
            "
            class="value"
          >
            <span>
              {{
                dataInstallment.amount
                  | currency('R$ ', 2, {
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                  })
              }}
            </span>
          </div>
          <div
            v-else-if="
              transaction.type === 'card' ||
              transaction.type === 'cardCommission' ||
              transaction.type === 'canceled'
            "
            class="value"
          >
            <span>
              {{
                transaction.amount
                  | currency('R$ ', 2, {
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                  })
              }}
            </span>
          </div>
          <div v-else-if="transaction.type === 'billPayment'" class="value">
            <span>{{
              (transaction.amount * -1)
                | currency('R$ ', 2, {
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
            }}</span>
          </div>
          <div v-else class="value">
            <span>
              {{
                transaction.amount
                  | currency('R$ ', 2, {
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                  })
              }}
            </span>
          </div>
          <div v-if="transaction.type === 'billing'" class="description">
            <span>
              {{ title }}
              <br />
              {{ transaction.description }}
            </span>
          </div>
          <div v-else class="description">
            <span>{{ title }}</span>
          </div>
          <span
            v-if="displayPrevisionDate"
            class="prevision"
          >
            {{ origin === 'extract' ? 'Creditado em' : 'Previsto Para' }}
            {{
              origin === 'extract'
                ? dataInstallment.creditedAt
                : dataInstallment.scheduledFor | moment('DD/MM/YYYY HH:mm:ss')
            }}
          </span>
          <span
            v-if="
              transaction.type === 'bankSlip' ||
              transaction.type === 'bankSlipCommission'
            "
            class="prevision"
          >
            {{ origin === 'extract' ? 'Creditado em' : 'Previsto Para' }}
            {{
              origin === 'extract'
                ? data.creditedAt
                : data.scheduledFor | moment('DD/MM/YYYY HH:mm:ss')
            }}
          </span>
          <span
            v-if="
              data.status &&
              data.status != 'error' &&
              (transaction.type === 'transfer' ||
                transaction.type === 'automaticTransfer' ||
                transaction.type === 'pixOrderCredit' ||
                transaction.type === 'pixOrderDebit' ||
                transaction.type === 'pixOrderDebitReturn')
            "
            class="prevision"
          >
            {{ transferStatus(data.status) }}
            {{ transferStatusDate(data.status) | moment('DD/MM/YYYY HH:mm:ss') }}
            {{
              data.status === 'rejected' && data.errors
                ? `- ${data.errors[0].message}`
                : ''
            }}
          </span>
          <span
            v-if="
              (transaction.type === 'card' ||
                transaction.type === 'cardCommission' ||
                transaction.type === 'canceled' ||
                transaction.type === 'contested') &&
              data.anticipated
            "
            class="text-uppercase"
          >
            Antecipada
          </span>
          <span
            v-if="transaction.type == 'billPayment'"
            class="text-uppercase mt-1"
          >
            {{ transaction.billPayment.paymentDate | moment('DD/MM/YYYY HH:mm:ss') }}
          </span>
          <span
            v-else-if="transaction.type === 'billPaymentReturn'"
            class="text-uppercase mt-1"
          >
            {{ transaction.billPayment.chargebackAt | moment('DD/MM/YYYY HH:mm:ss') }}
          </span>
          <div
            class="row my-4 w-100 justify-content-center"
            v-if="
              transaction.type !== 'otherTransfer' &&
              transaction.type !== 'bankTransferFee'
            "
          >
            <div class="col-6">
              <div
                class="badge-pill"
                :class="
                  transaction.type === 'liquidatingBankTransfer'
                    ? statusColor('successful')
                    : statusColor(data.status)
                "
              >
                <span v-if="transaction.type === 'liquidatingBankTransfer'"
                  >{{ statusName('successful', transaction.type) }}
                </span>
                <span v-else
                  >{{ statusName(data.status, transaction.type) }}
                </span>
              </div>
            </div>
            <div class="col" v-if="canRefund">
              <button
                id="btnRefund"
                type="button"
                class="btn"
                data-toggle="modal"
                :data-target="`#pixRefundModal`"
              >
                DEVOLVER VALOR
              </button>
            </div>
          </div>
          <div
            v-if="
              transaction.type == 'billPayment' &&
              transaction.type !== 'bankTransferFee'
            "
            class="badge-pill mt-2"
            :class="statusColor('paid')"
          >
            <span>{{ statusName('paid') }}</span>
          </div>
          <div
            v-if="transaction.type == 'billPaymentReturn'"
            class="badge-pill mt-2"
            :class="statusColor('chargeback')"
          >
            <span>{{ statusName('chargeback') }}</span>
          </div>
        </div>
        <div
          v-if="displayReleaseDetails"
          class="type-release"
        >
          <div class="type-release-details">
            <div
              v-if="
                transaction.type === 'card' ||
                transaction.type === 'cardCommission' ||
                transaction.type === 'canceled' ||
                transaction.type === 'contested' ||
                transaction.type === 'revertedChargeback' ||
                (transaction.type === 'commission' && transaction.card != null)
              "
              class="d-flex align-items-center"
            >
              <em
                class="payment-method mr-2"
                :class="transaction.card.cardBrand"
              />
              {{ data.credit ? 'Crédito' : 'Débito' }} final
              {{ data.cardFinal }}
            </div>
            <div
              v-if="transaction.type === 'billing' && transaction.card"
              class="d-flex align-items-center"
            >
              <em
                class="payment-method mr-2"
                :class="transaction.card.cardBrand"
              />
              Cartão final {{ transaction.card.cardNumber }}
            </div>
            <div
              v-if="
                transaction.type === 'bankSlip' ||
                transaction.type === 'bankSlipCommission'
              "
              class="d-flex"
            >
              <em class="icon bankslip-icon gray mr-2" />
              Boleto bancário
            </div>
          </div>
          <div
            v-if="
              transaction.type !== 'billing' &&
              transaction.type !== 'commission' &&
              transaction.type !== 'billPayment' &&
              transaction.type !== 'billPaymentReturn' &&
              transaction.type !== 'cardCommission' &&
              transaction.type !== 'bankSlipCommission'
            "
          >
            <router-link
              :to="{
                name: 'payment-details',
                params: { paymentId: data.paymentId },
              }"
            >
              <p @click="closeBackdrop()">Ver detalhes do pagamento</p>
            </router-link>
          </div>
          <div v-if="transaction.type === 'commission'">
            Data da transação: {{ data.commissionTransactionDate }}
          </div>
        </div>

        <div v-if="transaction.type === 'cardFee'" class="type-release">
          <div class="type-release-details d-flex align-items-center">
            <em
              class="payment-method mr-2"
              :class="transaction.card.cardBrand"
            />
            Final
            {{ transaction.card.cardNumber }}
          </div>
        </div>
        <div
          v-else-if="
            transaction.type !== 'bankTransferFee' &&
            transaction.type !== 'otherTransfer' &&
            transaction.type !== 'card'
          "
          class="content-modal-transfer fullWidth"
        >
          <span
            v-if="
              transaction.type !== 'pixOrderDebitReturn' &&
              transaction.type !== 'liquidatingBankTransfer'
            "
            :class="`type-transference-${getStatusStandardized(
              data.status
            )}  col-12`"
          >
            {{ getTransferStatusMessage(data) }}
          </span>
          <span
            v-if="transaction.type === 'pixOrderDebitReturn'"
            :class="`type-transference-${getStatusStandardized(
              data.status
            )}  col-12`"
          >
            <a
              href="#"
              style="color: white"
              @click.prevent="openOriginalTransaction()"
            >
              <i class="fa fa-external-link fa-lg mr-1" />
              Transação original
            </a>
          </span>
          <span
            v-if="transaction.type === 'liquidatingBankTransfer'"
            :class="`type-transference-approved col-12`"
          >
            {{ data.description }}
          </span>
        </div>
        <div class="details-release">
          <div v-if="transaction.type === 'bankSlip'" class="client">
            <div class="icon">
              <em class="icon-user" />
            </div>
            <div class="data">
              <p class="text-uppercase">Cliente</p>
              <span class="block">{{ data.payerName }}</span>
              <span class="block">{{ data.payerTaxDocument }}</span>
            </div>
          </div>
          <div v-if="transaction.type === 'bankSlipFee'" class="client">
            <div class="icon">
              <em class="icon-user" />
            </div>
            <div class="data">
              <p class="text-uppercase">Cliente</p>
              <span class="block">{{ transaction.bankSlip.payerName }}</span>
            </div>
          </div>
          <div v-if="transaction.type === 'billPayment'" class="client">
            <div class="icon">
              <em class="icon-user" />
            </div>
            <div class="data">
              <p class="text-uppercase">Beneficiário</p>
              <span class="block">
                {{ transaction.billPayment.recipientName }}
              </span>
              <span class="block">
                {{ transaction.billPayment.recipientDocument }}
              </span>
            </div>
          </div>
          <div v-if="transaction.type === 'billPaymentReturn'" class="client">
            <div class="icon">
              <em class="icon-user" />
            </div>
            <div class="data">
              <span class="block">
                {{ transaction.billPayment.recipientName }}
              </span>
              <span class="block">
                {{ transaction.billPayment.recipientDocument }}
              </span>
            </div>
          </div>
          <div
            class="total-received"
            :class="
              transaction.type === 'bankTransferFee' ||
              transaction.type === 'returnBankTransferFee'
                ? `mb-4`
                : ``
            "
          >
            <em class="icon money-icon gray mt-4 ml-3 pr-3 m-0" />
            <div class="data">
              <span
                v-if="
                  transaction.type === 'card' ||
                  transaction.type === 'approved' ||
                  transaction.type === 'canceled' ||
                  transaction.type === 'contested' ||
                  transaction.type === 'billing'
                "
                class="text-uppercase block mt-4"
              >
                Valor da parcela
              </span>
              <span
                v-else-if="transaction.type === 'bankSlip'"
                class="text-uppercase block mt-4"
              >
                Valor do boleto
              </span>
              <span
                v-else-if="
                  transaction.type !== 'cardCommission' &&
                  transaction.type !== 'bankSlipCommission'
                "
                class="text-uppercase block mt-4"
              >
                Valor
              </span>
              <span
                v-if="
                  transaction.type === 'bankTransferFee' ||
                  transaction.type === 'pixFee' ||
                  transaction.type === 'bankSlipFee' ||
                  transaction.type === 'cardFee'
                "
                class="text-uppercase value-total"
              >
                {{
                  (transaction.amount * -1)
                    | currency('R$ ', 2, {
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}
              </span>
              <span
                v-else-if="transaction.type === 'returnBankTransferFee'"
                class="text-uppercase value-total"
              >
                {{
                  transaction.amount
                    | currency('R$ ', 2, {
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}
              </span>
              <span
                v-else-if="transaction.type === 'bankSlip'"
                class="text-uppercase value-total"
              >
                {{
                  data.amount
                    | currency('R$ ', 2, {
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}
              </span>
              <span
                v-else-if="
                  transaction.type === 'card' ||
                  transaction.type === 'approved' ||
                  transaction.type === 'canceled' ||
                  transaction.type === 'contested'
                "
                class="text-uppercase value-total"
              >
                {{
                  data.installmentValue
                    | currency('R$ ', 2, {
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}
              </span>
              <span
                v-else-if="transaction.type === 'billPayment'"
                class="text-uppercase value-total"
              >
                {{
                  (transaction.amount * -1)
                    | currency('R$ ', 2, {
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}
              </span>
              <span
                v-else-if="
                  transaction.type !== 'cardCommission' &&
                  transaction.type !== 'bankSlipCommission'
                "
                class="text-uppercase value-total"
              >
                {{
                  data.amount
                    | currency('R$ ', 2, {
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}
              </span>
              <div
                v-if="
                  transaction.type === 'card' ||
                  transaction.type === 'canceled' ||
                  transaction.type === 'contested'
                "
              >
                <span class="text-uppercase block">
                  Taxas(Mdr + Antecipação)
                </span>
                <span class="text-uppercase value">
                  - {{ data.appliedFees }}%
                </span>
                <span v-if="data.cardFee" class="text-uppercase block">
                  Taxa de Antifraude (debitada na primeira parcela)
                </span>
                <span v-if="data.cardFee" class="text-uppercase value">
                  {{
                    data.cardFee
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>
                <span
                  v-if="dataInstallment.splitAmount"
                  class="text-uppercase block"
                >
                  Valor bruto de repasse ao Splitter
                </span>
                <span
                  v-if="dataInstallment.splitAmount"
                  class="text-uppercase value"
                >
                  {{
                    dataInstallment.splitAmount
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>
              </div>
              <div v-if="transaction.type === 'bankSlip'" class="mt-2">
                <span class="text-uppercase block">Valor Pago</span>
                <span class="text-uppercase value">
                  {{
                    data.amountPaid
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>

                <span v-if="data.bankSlipFee" class="text-uppercase block">
                  Taxas
                </span>
                <span class="text-uppercase value">
                  {{
                    data.bankSlipFee
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>

                <span v-if="data.interestPaid" class="text-uppercase block">
                  Juros
                </span>
                <span class="text-uppercase value">
                  {{
                    data.interestPaid
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>

                <span v-if="data.finesPaid" class="text-uppercase block">
                  Multa
                </span>
                <span class="text-uppercase value">
                  {{
                    data.finesPaid
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>
                <span v-if="data.splitAmount" class="text-uppercase block">
                  Valor bruto de repasse ao Splitter
                </span>
                <span v-if="data.splitAmount" class="text-uppercase value">
                  {{
                    data.splitAmount
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>
              </div>
              <div
                v-if="
                  transaction.type === 'transfer' ||
                  transaction.type === 'pixOrderCredit' ||
                  transaction.type === 'pixOrderDebit' ||
                  transaction.type === 'pixOrderDebitReturn' ||
                  transaction.type === 'liquidatingBankTransfer'
                "
                class="mt-2"
              >
                <span class="text-uppercase block">Taxa de Transferência</span>
                <span
                  v-if="transaction.type === 'transfer'"
                  class="text-uppercase value"
                >
                  {{
                    data.transferFee
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>
                <span
                  v-if="
                    transaction.type === 'pixOrderCredit' ||
                    transaction.type === 'pixOrderDebit' ||
                    transaction.type === 'pixOrderDebitReturn'
                  "
                  class="text-uppercase value"
                >
                  {{
                    data.fee
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>
                <span
                  v-if="transaction.type === 'liquidatingBankTransfer'"
                  class="text-uppercase value"
                >
                  {{
                    data.liquidatingBankTransfer.bankTransferFee
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>
                <span
                  class="text-uppercase block"
                  v-if="transaction.type === 'pixOrderDebit'"
                >
                  Valor debitado da sua conta
                </span>
                <span
                  class="text-uppercase block"
                  v-if="
                    transaction.type === 'pixOrderCredit' ||
                    transaction.type === 'pixOrderDebitReturn'
                  "
                >
                  Valor creditado na sua conta
                </span>
                <span
                  v-if="transaction.type === 'transfer'"
                  class="text-uppercase value"
                >
                  {{
                    data.debitedAmount
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>
                <span
                  class="text-uppercase value"
                  v-if="
                    transaction.type === 'pixOrderCredit' &&
                    data.status != 'rejected'
                  "
                >
                  {{
                    (data.amount - data.fee)
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>
                <span
                  v-if="
                    (transaction.type === 'pixOrderDebit' &&
                      data.status != 'rejected') ||
                    transaction.type === 'pixOrderDebitReturn'
                  "
                  class="text-uppercase value"
                >
                  {{
                    (data.fee + data.amount)
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>
                <span
                  class="text-uppercase value"
                  v-if="
                    (transaction.type === 'pixOrderCredit' ||
                      transaction.type === 'pixOrderDebit') &&
                    data.status == 'rejected'
                  "
                >
                  {{
                    0
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>
              </div>
              <div v-if="transaction.type === 'commission'" class="mt-2">
                <span v-if="data.holderFee" class="text-uppercase block">
                  Taxa Cobrada
                </span>
                <span
                  v-if="transaction.card != null"
                  class="text-uppercase value"
                >
                  {{ data.holderFee }} %
                </span>
                <span v-else class="text-uppercase value">
                  {{
                    data.holderFee
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>

                <span v-if="data.partnerFee" class="text-uppercase block">
                  Taxa Paga
                </span>
                <span
                  v-if="transaction.card != null"
                  class="text-uppercase value"
                >
                  {{ data.partnerFee }} %
                </span>
                <span v-else class="text-uppercase value">
                  {{
                    data.partnerFee
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="transaction.type === 'internalTransfer'"
            class="client mt-4"
          >
            <div class="icon">
              <em class="icon-user" />
            </div>
            <div
              v-if="
                transaction.type === 'internalTransfer' &&
                data.transferInformation !== null &&
                data.transferInformation.source !== null &&
                data.transferInformation.destination === null
              "
              class="data"
            >
              <p class="text-uppercase">Origem</p>
              <span class="block">
                {{ data.transferInformation.source.fullName }}
              </span>
              <span class="block">
                {{ data.transferInformation.source.taxDocument }}
              </span>
            </div>
            <div
              v-if="
                transaction.type === 'internalTransfer' &&
                data.transferInformation !== null &&
                data.transferInformation.source === null &&
                data.transferInformation.destination !== null
              "
              class="data"
            >
              <p class="text-uppercase">Destino</p>
              <span class="block">
                {{ data.transferInformation.destination.fullName }}
              </span>
              <span class="block">
                {{ data.transferInformation.destination.taxDocument }}
              </span>
            </div>
            <div
              v-if="
                transaction.type === 'internalTransfer' &&
                data.transferInformation === null
              "
              class="data"
            >
              <p class="text-uppercase">Favorecido</p>
              <span class="block">{{ data.bankAccount.holderFullName }}</span>
              <span class="block">
                {{ data.bankAccount.holderTaxDocument }}
              </span>
            </div>
          </div>
          <div
            v-if="transaction.type === 'internalTransfer' && transactionDetails.approvedAt"
            class="client mt-4 mb-4"
          >
            <div class="icon">
              <em class="icon calendar-outline gray mt-4 ml-3 pr-3 m-0" />
            </div>
            <div class="data">
              <p class="text-uppercase">
                <b>Data de Transferência</b>
              </p>
              <span class="block">
                {{ transactionDetails.approvedAt | moment('DD/MM/YYYY HH:mm:ss') }}
              </span>
            </div>
          </div>
          <div
            v-if="
              transaction.type === 'transfer' ||
              transaction.type === 'automaticTransfer'
            "
            class="client mt-4"
          >
            <div class="icon">
              <em class="icon bank-icon gray mt-4 ml-3 pr-3 m-0" />
            </div>
            <div class="data">
              <p class="text-uppercase">Destino</p>
              <span class="block">
                <b>{{ bankName(data.bankAccount.bankNumber) }}</b>
              </span>
              <span v-if="data.bankAccount.bankBranchNumber" class="block">
                <b>Agência:</b>
                {{ data.bankAccount.bankBranchNumber }}
              </span>
              <span v-if="data.bankAccount.type" class="block">
                <b>
                  Conta
                  {{
                    data.bankAccount.type.length === 1
                      ? data.bankAccount.type === 'C'
                        ? 'Corrente'
                        : 'Poupança'
                      : data.bankAccount.type
                  }}
                </b>
                : {{ data.bankAccount.accountNumber }} &nbsp;&nbsp;&nbsp;
                <b v-if="data.bankAccount.variation">VAR:</b>
                {{
                  data.bankAccount.variation ? data.bankAccount.variation : ``
                }}
              </span>
              <span class="block">{{ data.bankAccount.holderFullName }}</span>
              <span class="block">
                {{ data.bankAccount.holderTaxDocument }}
              </span>
            </div>
          </div>
          <div
            v-if="
              transaction.type === 'pixOrderCredit' ||
              transaction.type === 'pixOrderDebit'
            "
          >
            <div class="client mt-4">
              <div class="icon">
                <em class="icon bank-icon gray mt-4 ml-3 pr-3 m-0" />
              </div>
              <div class="data">
                <p class="text-uppercase">Origem</p>
                <span v-if="data.origin.name" class="block">
                  {{ data.origin.name }}
                </span>
                <span v-if="data.origin.institution" class="block">
                  <b>{{ bankName(data.origin.institution) }}</b>
                </span>
                <span v-if="data.origin.branch" class="block">
                  <b>Agência:</b>
                  {{ data.origin.branch }}
                </span>
                <span class="block">
                  <b>
                    Conta
                    {{
                      data.origin.type === 'checking' ? 'Corrente' : 'Poupança'
                    }}
                  </b>
                  : {{ data.origin.number }} &nbsp;&nbsp;&nbsp;
                  <b v-if="data.origin.variation">VAR:</b>
                  {{ data.origin.variation ? data.origin.variation : `` }}
                </span>
                <span v-if="data.origin.taxDocument" class="block">
                  <b>Documento: </b>{{ data.origin.taxDocument }}</span
                >
              </div>
            </div>

            <div class="client mt-4">
              <div class="icon">
                <em class="icon bank-icon gray mt-4 ml-3 pr-3 m-0" />
              </div>
              <div class="data">
                <p class="text-uppercase">Destino</p>
                <span v-if="data.destination.institution" class="block">
                  <b>{{ bankName(data.destination.institution) }}</b>
                </span>
                <span v-if="data.destination.branch" class="block">
                  <b>Agência:</b>
                  {{ data.destination.branch }}
                </span>
                <span class="block">
                  <b>
                    Conta
                    {{
                      data.destination.type === 'checking'
                        ? 'Corrente'
                        : 'Poupança'
                    }}
                  </b>
                  : {{ data.destination.number }} &nbsp;&nbsp;&nbsp;
                  <b v-if="data.destination.variation">VAR:</b>
                  {{
                    data.destination.variation ? data.destination.variation : ``
                  }}
                </span>
                <span v-if="data.destination.name" class="block">
                  {{ data.destination.name }}
                </span>
                <span v-if="data.destination.taxDocument" class="block">
                  <b>Documento: </b>{{ data.destination.taxDocument }}
                </span>
              </div>
            </div>
          </div>
          <!-- LiquidatingBankTransfer -->
          <div
            v-if="
              transaction.type === 'liquidatingBankTransfer' &&
              data.liquidatingBankTransfer !== null
            "
          >
            <div class="client mt-4">
              <div class="icon">
                <em class="icon bank-icon gray mt-4 ml-3 pr-3 m-0" />
              </div>
              <div class="data">
                <p class="text-uppercase">Origem</p>
                <span
                  v-if="data.transferInformation.source.fullName"
                  class="block"
                >
                  {{ data.transferInformation.source.fullName }}
                </span>
                <span
                  v-if="data.transferInformation.source.institution"
                  class="block"
                >
                  <b>{{ data.transferInformation.source.institution }}</b>
                </span>
                <span
                  v-if="data.transferInformation.source.branchNumber"
                  class="block"
                >
                  <b>Agência:</b>
                  {{ data.transferInformation.source.branchNumber }}
                </span>
                <span class="block">
                  <b>
                    Conta
                    {{
                      data.transferInformation.source.type === 'checking'
                        ? 'Corrente'
                        : 'Poupança'
                    }}
                  </b>
                  :
                  {{ data.transferInformation.source.accountNumber }}
                  &nbsp;&nbsp;&nbsp;
                </span>
                <span
                  v-if="data.transferInformation.source.taxDocument"
                  class="block"
                >
                  <b>Documento: </b
                  >{{ data.transferInformation.source.taxDocument }}</span
                >
              </div>
            </div>
            <div class="client mt-4">
              <div class="icon">
                <em class="icon bank-icon gray mt-4 ml-3 pr-3 m-0" />
              </div>
              <div class="data">
                <p class="text-uppercase">Destino</p>
                <span
                  v-if="data.transferInformation.destination.branchNumber"
                  class="block"
                >
                  <b>Agência:</b>
                  {{ data.transferInformation.destination.branchNumber }}
                </span>
                <span class="block">
                  <b>
                    Conta
                    {{
                      data.transferInformation.destination.accountType ===
                      'checking'
                        ? 'Corrente'
                        : 'Poupança'
                    }}
                  </b>
                  :
                  {{ data.transferInformation.destination.accountNumber }}
                  &nbsp;&nbsp;&nbsp;
                </span>
                <span
                  v-if="data.transferInformation.destination.fullName"
                  class="block"
                >
                  {{ data.transferInformation.destination.fullName }}
                </span>
                <span
                  v-if="data.transferInformation.destination.taxDocument"
                  class="block"
                >
                  <b>Documento: </b
                  >{{ data.transferInformation.destination.taxDocument }}
                </span>
              </div>
            </div>
          </div>

          <div
            v-if="
              (transaction.type === 'transfer' ||
                transaction.type === 'automaticTransfer') &&
              data.estimatedAt
            "
            class="client mt-4 mb-4"
          >
            <div class="icon">
              <em class="icon calendar-outline gray mt-4 ml-3 pr-3 m-0" />
            </div>
            <div v-if="data.estimatedAt" class="data">
              <p class="text-uppercase">
                <b>Previsão</b>
              </p>
              <span class="block">
                {{ data.estimatedAt | moment('DD/MM/YYYY HH:mm:ss') }}
              </span>
            </div>
          </div>
          <div
            v-if="transaction.type === 'otherTransfer'"
            class="total-received mb-5 mt-4"
          >
            <em class="icon info-icon gray mt-4 ml-3 pr-3 m-0" />
            <span class="text-uppercase block mt-4">
              {{ transaction.description }}
            </span>
          </div>
          <div
            v-if="
              transaction.type === 'card' ||
              transaction.type === 'canceled' ||
              transaction.type === 'contested'
            "
            class="sale-value"
          >
            <em class="icon generic-card-icon gray" />
            <div class="data">
              <span class="text-uppercase block">Valor da Transação</span>
              <span class="text-uppercase block">
                {{
                  data.amountPaid
                    | currency('R$ ', 2, {
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}
              </span>
              <span class="text-uppercase value">
                {{ data.installmentsNumber }} x R$
                {{ data.installmentValue }}
              </span>
            </div>
          </div>
        </div>
        <AppTimeLine
          v-if="displayTimeline"
          :data="data.timeline"
        />
      </div>
      <div v-else>{{ error }}</div>

      <PixRefundModal
        :modalId="'pixRefundModal'"
        :orderId="transaction.id"
        :originalAmount="transaction.amount"
        @refunded="onOrderRefunded"
      />
    </div>
  </ModalBase>
</template>

<script>
import ModalBase from '@/components/shared/ModalBase';
import AppTimeLine from '@/components/account/transference/TimeLine';
import AppIconHistoric from '@/components/shared/IconHistoric';
import moment from 'moment';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import AccountApi from '@/services/AccountApi';
import PixRefundModal from '@/components/pix/transactions/PixRefundModal.vue';

export default {
  name: 'AppModalReleaseDetails',

  components: {
    ModalBase,
    AppTimeLine,
    AppIconHistoric,
    PixRefundModal,
  },

  props: {
    id: {
      type: String,
      default: '',
    },

    transaction: {
      type: Object,
      required: true,
      default: null,
    },

    origin: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    data: null,
    dataInstallment: {
      number: null,
      amount: null,
      creditedAt: null,
      scheduledFor: null,
    },
    error: null,
    listBanks: [],
    transactionDetails: null,
  }),

  computed: {
    displayPrevisionDate(){
      return this.transaction.type === 'card' ||
        this.transaction.type === 'cardCommission' ||
        this.transaction.type === 'canceled' ||
        this.transaction.type === 'contested' ||
        this.transaction.type === 'revertedChargeback';
    },
    displayReleaseDetails(){
      return this.transaction.type === 'card' ||
        this.transaction.type === 'cardCommission' ||
        this.transaction.type === 'canceled' ||
        this.transaction.type === 'bankSlip' ||
        this.transaction.type === 'bankSlipCommission' ||
        this.transaction.type === 'contested' ||
        this.transaction.type === 'billing' ||
        this.transaction.type === 'revertedChargeback' ||
        this.transaction.type === 'commission' ||
        this.transaction.type === 'billPayment' ||
        this.transaction.type === 'billPaymentReturn';
    },
    displayTimeline(){
      return this.transaction.type === 'card' ||
        this.transaction.type === 'cardCommission' ||
        this.transaction.type === 'canceled' ||
        this.transaction.type === 'contested' ||
        this.transaction.type === 'revertedChargeback' ||
        this.transaction.type === 'bankSlip' ||
        this.transaction.type === 'bankSlipCommission' ||
        this.transaction.type === 'internalTransfer' ||
        this.transaction.type === 'transfer' ||
        this.transaction.type === 'automaticTransfer' ||
        this.transaction.type === 'billing';
    },
    canRefund() {
      return (
        this.transaction.type === 'pixOrderCredit' &&
        this.transaction.refundedAmount < this.transaction.amount
      );
    },

    percentageFees() {
      return this.data.anticipated
        ? this.data.cardBrandFee + this.data.anticipationFee
        : this.data.cardBrandFee;
    },

    monetaryFees() {
      return (this.paidInstallmentValue / 100) * this.percentageFees;
    },

    paidInstallmentValue() {
      return this.data.amountPaid / this.data.installmentsNumber;
    },

    title() {
      switch (this.transaction.type) {
        case 'card':
          return `Parcela de Cartão ${
            this.transaction.card.installments > 1
              ? `- ${this.transaction.card.installmentNumber}/${this.transaction.card.installments}`
              : ''
          } - #${this.transaction.card.nsu}`;

        case 'cardFee':
          return `Taxa parcela de Cartão ${
            this.transaction.card.installments > 1
              ? `- ${this.transaction.card.installmentNumber}/${this.transaction.card.installments}`
              : ''
          } - #${this.transaction.card.nsu}`;

        case 'bankSlip':
          return `Boleto ${
            this.transaction.bankSlip.installments > 1
              ? `- ${this.transaction.bankSlip.installmentNumber}/${this.transaction.bankSlip.installments}`
              : ''
          } - #${this.transaction.bankSlip.bankSlipNumber}`;

        case 'bankSlipFee':
          return `Taxa boleto ${
            this.transaction.bankSlip.installments > 1
              ? `- ${this.transaction.bankSlip.installmentNumber}/${this.transaction.bankSlip.installments}`
              : ''
          } - #${this.transaction.bankSlip.bankSlipNumber}`;

        case 'otherTransfer':
          return `${
            this.transaction.amount > 0 ? 'Crédito' : 'Débito'
          } diverso${
            this.transaction.id !== null ? ` #${this.transaction.id}` : ''
          }`;

        case 'bankTransferFee':
          return 'Taxa de transferência bancária';

        case 'automaticTransfer':
          return `Transferência automática ${
            this.transaction.transfer.id !== null
              ? ` #${this.transaction.transfer.id}`
              : ''
          }`;
        case 'internalTransfer':
          return `Transferência ${
            this.transaction.transfer.id !== null
              ? ` #${this.transaction.transfer.id}`
              : ''
          }`;
        case 'transfer':
          return `Transferência ${
            this.transaction.transfer.id !== null
              ? ` #${this.transaction.transfer.id}`
              : ''
          }`;
        case 'billing': {
          if (this.transaction.card != null) {
            return `Parcela de Cartão ${
              this.transaction.card.installments > 1
                ? `- ${this.transaction.card.installmentNumber}/${this.transaction.card.installments}`
                : ''
            } - #${this.transaction.card.nsu}`;
          } else {
            return `Boleto ${
              this.transaction.bankSlip.installments > 1
                ? `- ${this.transaction.bankSlip.installmentNumber}/${this.transaction.bankSlip.installments}`
                : ''
            } - #${this.transaction.bankSlip.number}`;
          }
        }
        case 'commission': {
          if (this.transaction.card != null) {
            return `Comissão - Transação com Cartão - #${
              this.transaction.card.nsu
            } ${
              this.transaction.card.installments > 1
                ? `- ${this.transaction.card.installmentNumber}/${this.transaction.card.installments}`
                : ''
            }`;
          } else {
            return `Comissão - Boleto #${this.transaction.bankSlip.bankSlipNumber}`;
          }
        }
        case 'billPayment': {
          return `Pagamento - #${this.transaction.billPayment.id}`;
        }

        case 'billPaymentFee':
          return 'Taxa de pagamento de conta';

        case 'billPaymentReturn':
          return `Estorno do pagamento - #${this.transaction.billPayment.id}`;

        case 'pixOrderDebit': {
          return 'Transferência de débito Pix';
        }

        case 'pixOrderDebitReturn': {
          return 'Retorno transferência de débito Pix';
        }

        case 'pixOrderCredit': {
          return 'Transferência de crédito Pix';
        }

        case 'pixFee': {
          return 'Taxa de transferência Pix';
        }
        case 'pixFeeReturn': {
          return 'Devolução taxa de transferência Pix';
        }

        case 'returnBankTransferFee': {
          return 'Devolução de taxa de transferência';
        }

        case 'liquidatingBankTransfer': {
          return 'Crédito TED';
        }

        case 'revertedChargeback': {
          return 'Devolução de parcela estornada';
        }

        default:
          return null;
      }
    },
  },

  watch: {
    transaction: async function () {
      this.$bus.$emit('spinner-run');
      const api = new PaymentAccountsApi();
      this.data = {
        ...this.transaction,
        origin: {},
        destination: {},
        bankAccount: {},
      };
      if (
        this.transaction.type === 'billing' ||
        this.transaction.type === 'internalTransfer'
      ) {
        $(`#${this.id}`).modal('toggle');
        if(this.transaction.type === 'internalTransfer'){
        let response = await api.getDetail(this.transaction.detailsLink);
        if (response && response.error) {
          this.error = response.error;
        } else {
          this.transactionDetails = response;
        }
      }
      } else if (
        this.transaction.id === null ||
        this.transaction.detailsLink === null
      ) {
        $(`#${this.id}`).modal('toggle');
      } else {
        let res = await api.getDetail(this.transaction.detailsLink);
        if (res && res.error) {
          this.error = res.error;
        } else {
          this.data = res;
          if (
            this.transaction.type === 'card' ||
            this.transaction.type === 'cardCommission' ||
            this.transaction.type === 'canceled' ||
            this.transaction.type === 'revertedChargeback'
          ) {
            this.dataInstallment = this.data.installments.find(
              (x) => x.number === this.transaction.card.installmentNumber
            );
          }
        }
      }
      this.$bus.$emit('spinner-stop');
    },
  },

  async created() {
    await this.banks();
  },

  methods: {
    async banks() {
      const api = new AccountApi();
      this.listBanks = await api.banks();
    },

    bankName(bankNumber) {
      const bank = this.listBanks.banks.find((x) => x.number === bankNumber);
      return bank ? bank.name : '';
    },

    statusColor(status, type = null) {
      if (type == 'pixOrderDebitReturn') return 'big-badge-success';

      return {
        'big-badge-success':
          status === 'approved' || status === 'paid' || status === 'successful',
        'big-badge-alert': status === 'chargeback' || status === 'error',
        'big-badge-canceled': status === 'canceled' || status === 'contested',
        'big-badge-reproved': status === 'reproved' || status === 'rejected',
        'big-badge-pending': status === 'provisioned',
        'big-badge-gin-blue': status === 'pending' || status === 'submitted' || status === 'devolution',
      };
    },

    getStatusStandardized(status) {
      if (this.transaction.type === 'pixOrderDebitReturn') return 'approved';
      if (
        this.transaction.type === 'pixOrderDebit' ||
        this.transaction.type === 'pixOrderCredit'
      ) {
        switch (status) {
          case 'successful':
            return 'approved';
          case 'error':
            return 'reproved';
          case 'rejected':
            return 'reproved';
          case 'submitted':
            return 'pending';
          case 'devolution':
            return 'pending';
          default:
            return 'provisioned';
        }
      }
      return status;
    },

    statusName(status, type = null) {
      if (type === 'pixOrderDebit' && status === 'devolution')
        return 'Devolvido';

      if (
        (type === 'pixOrderCredit' || type === 'liquidatingBankTransfer') &&
        status === 'successful'
      )
        return 'Recebida';
      if (type === 'pixOrderDebitReturn' && status === 'rejected')
        return 'Reembolsada';

      switch (status) {
        case 'provisioned':
          return 'Provisionada';
        case 'pending':
          return 'Pendente';
        case 'approved':
          return 'Confirmada';
        case 'paid':
          return 'Pago';
        case 'reproved':
          return 'Devolvida';
        case 'canceled':
          return 'Cancelada';
        case 'contested':
          return 'Contestada';
        case 'chargeback':
          return 'Estornada';
        case 'submitted':
          return 'Enviada';
        case 'successful':
          return 'Paga';
        case 'rejected':
          return 'Reprovada';
        case 'devolution':
          return 'Devolvido';
        case 'error':
          return 'Erro';
        default:
          break;
      }
    },

    transferStatus(type) {
      switch (type) {
        case 'approved':
          return 'Aprovada em';
        case 'reproved':
          return 'Devolvida em';
        case 'canceled':
          return 'Cancelada em';
        case 'contested':
          return 'Contestada em';
        case 'provisioned':
          return 'Estimada para';
        case 'pending':
          return 'Estimada para';
        case 'submitted':
          return 'Enviada em';
        case 'successful':
          return 'Paga em';
        case 'rejected':
          return 'Reprovada em';
        case 'devolution':
          return 'Devolvido em';
        default:
          break;
      }
    },

    transferStatusDate(type) {
      switch (type) {
        case 'approved':
          return this.data.approvedAt;
        case 'reproved':
          return this.data.reprovedAt;
        case 'canceled':
          return this.data.canceledAt;
        case 'provisioned':
          return this.data.estimatedAt;
        case 'pending':
          return this.data.estimatedAt;
        case 'contested':
          return this.data.contestedOn;
        case 'submitted':
          return this.data.createdAt;
        case 'successful':
          return this.data.approvedAt;
        case 'rejected':
          return this.data.reprovedAt;
        case 'devolution':
          return this.data.approvedAt;
        default:
          break;
      }
    },

    getTransferStatusMessage(data) {
      if (data === undefined || data === null) return '';

      if (data.status === undefined || data.status === null) return '';

      if (
        this.transaction.type === 'pixOrderDebit' ||
        this.transaction.type === 'pixOrderCredit' ||
        this.transaction.type === 'pixOrderDebitReturn'
      )
        return data.note;

      if(this.transaction.type == 'revertedChargeback')
            return 'O estorno foi cancelado e o dinheiro devolvido à sua conta. Consulte seu extrato.';

      switch (data.status.toLowerCase()) {
        case 'pending':
          return 'Sua transferência foi solicitada. Se tudo der certo o dinheiro cai na conta em breve.';
        case 'approved':
          return `Dinheiro na conta! ${
            data.type === 'internalTransfer'
              ? 'O dinheiro foi transferido e já está disponível.'
              : `${`Recebemos a confirmação do banco em ${moment(
                  data.approvedAt
                ).format('LLL')}`}`
          }.`;
        case 'provisioned':
          return 'Dinheiro a caminho do banco, fique atento à sua conta bancária.';
        case 'canceled':
          return 'A transferência foi cancelada e o dinheiro devolvido à sua conta. Consulte seu extrato.';
        case 'reproved':
          return 'Aconteceu algum problema com a conta informada. Verifique seus dados bancários.';
        default:
          break;
      }
    },

    closeBackdrop() {
      $(`#${this.id}`).modal('toggle');
    },

    openOriginalTransaction() {
      this.$emit('open-transaction', this.transaction);
    },

    onOrderRefunded() {
      this.$emit('order-refunded');
      this.closeBackdrop();
    },
  },
};
</script>

<style lang="scss" scoped>
.fullWidth {
  width: 100%;
}

#btnRefund:hover,
#btnRefund:focus {
  outline: none !important;
  box-shadow: none !important;
}

#btnRefund:hover {
  opacity: 0.8;
}
</style>
